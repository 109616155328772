body {
  font-family: "Montserrat", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.leaflet-popup {
  width: 310px;
  background: #ffffff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
  z-index: 1001;
}

@media (min-width: 1200px) {
  .leaflet-popup {
    width: 483px;
    height: 206px;
  }
}

.leaflet-popup-content-wrapper {
  width: 100%;
  box-shadow: none;
  height: 100%;
}

@media (min-width: 1200px) {
  .leaflet-popup-content-wrapper {
    margin-top: -1px;
  }
}

.leaflet-popup-content {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100% !important;
  height: 100%;
}

@media (min-width: 1200px) {
  .leaflet-popup-content {
    flex-direction: row;
  }
}

.leaflet-popup-content-info {
  padding: 24px 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 1200px) {
  .leaflet-popup-content-info {
    padding: 14px 26px 0 12px;
    max-width: 240px;
    align-items: flex-start;
  }
}

.leaflet-popup-content-text {
  margin: 0 !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: #0a055f;
}

@media (min-width: 1200px) {
  .leaflet-popup-content-text {
    text-align: left;
  }
}

.leaflet-popup-content-pik-logo {
  margin-top: 14px;
  width: 18px;
  height: 18px;
}

@media (min-width: 1200px) {
  .leaflet-popup-content-pik-logo {
    margin-top: 20px;
  }
}

.leaflet-popup-content-image {
  display: none;
}

@media (min-width: 1200px) {
  .leaflet-popup-content-image {
    display: block;
    width: 208px;
    height: 208px;
  }
}

.leaflet-popup-tip-container {
  display: none;
}

.leaflet-control-zoom {
  border: none !important;
  border-radius: 5px;
  overflow: hidden;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  padding-top: 2px;
  border: none !important;
  color: #929292 !important;
  box-sizing: border-box;
  line-height: 1 !important;
  display: flex !important;
  justify-content: center;
}

.leaflet-popup-close-button {
  width: 20px !important;
  height: 20px !important;
  font-size: 20px !important;
  line-height: 1 !important;
  top: 0 !important;
}

@media (min-width: 1200px) {
  .leaflet-popup-close-button {
    display: none;
  }
}
